// костыть для избежания дергания карточек при наведении
// TODO: выпилить и написать нормальное решение проблемы

const setCardMinHeight = () => {
    const catalogBlock = document.querySelector<HTMLElement>('.catalog__list')
    const cards = catalogBlock?.querySelectorAll<HTMLElement>('.product-card')

    if (cards && cards.length > 0) {
        let minHeight = 0;
        const lastIndex = cards.length - 1

        cards.forEach((card, i) => {
            if (card.offsetHeight > minHeight && lastIndex !== i) {
                minHeight = card.offsetHeight
            }
        })

        if (minHeight > 0) {
            document.documentElement.style.setProperty('--card-min-h', `${minHeight}px`)
        }
    }
}

document.addEventListener('productListUpdated', setCardMinHeight)
document.addEventListener('DOMContentLoaded', setCardMinHeight, {once: true})
window.addEventListener('load', setCardMinHeight, {once: true})
