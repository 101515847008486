interface ProductSalesInput {
    count: number,
    element: HTMLInputElement
}

interface ProductSalesInputsMapItem {
    countElement: HTMLInputElement,
    salesElements: ProductSalesInput[]
}

const productSalesInputsMap: Map<string, ProductSalesInputsMapItem> = new Map()

document.addEventListener('DOMContentLoaded', () => {
    initProductSalesInputsMap()
    setSalesInputEvents()
})

document.addEventListener('dropdownClose', (e) => {
    const modal = e.target as HTMLElement;
    const countEl = modal.querySelector<HTMLInputElement>(`.input-count__quantity[data-id-product-attribute]`)

    if (countEl && countEl.dataset.idProductAttribute) {
        productSalesInputsMap.delete(countEl.dataset.idProductAttribute)
    }
});

document.addEventListener('productQuantityChangeByModal', (e) => {
    const productAttributeID = (e as CustomEvent).detail.attributeID as string;

    if (!productSalesInputsMap.has(productAttributeID)) {
        const dataAttribute = `data-id-product-attribute="${productAttributeID}"`;
        const countEl = document.querySelector<HTMLInputElement>(`.input-count__quantity[${dataAttribute}]`)

        if (!countEl) {
            return
        }

        fillProductsMap(countEl, productAttributeID)
    }

    setSaleInputValue(productAttributeID)
})


function initProductSalesInputsMap() {
    document.querySelectorAll<HTMLInputElement>(`.input-count__quantity`).forEach((countEl) => {
        const productAttributeID = countEl.dataset.idProductAttribute as string;

        fillProductsMap(countEl, productAttributeID)
        setCountBlockEvents(countEl, productAttributeID)
    })
}

function setSalesInputEvents() {
    document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        const label = target.closest(`.product-card-sales-list__field-label`) as HTMLLabelElement|null;

        if (label) {
            const productAttributeID = label.dataset.idProductAttribute as string;

            if (productSalesInputsMap.has(productAttributeID)) {
                const mapItem = productSalesInputsMap.get(productAttributeID) as ProductSalesInputsMapItem;
                const quantity = parseInt((label.control as HTMLInputElement).dataset.quantity as string, 10)

                setCountInputValue(mapItem.countElement, quantity)

                mapItem.countElement.dispatchEvent(new Event('change'))
            }
        }
    })
}

function setSaleInputValue(productAttributeID: string) {
    if (productSalesInputsMap.has(productAttributeID)) {
        const mapItem = productSalesInputsMap.get(productAttributeID) as ProductSalesInputsMapItem;
        const value = parseInt(mapItem.countElement.value, 10);
        const salesElements = mapItem.salesElements;
        const closestProductSalesInput = findClosestProductSalesInput(value, salesElements)

        if (closestProductSalesInput) {
            salesElements.forEach((salesInput) => {
                salesInput.element.checked = salesInput === closestProductSalesInput
            })

            return;
        }

        salesElements.forEach((salesInput) => salesInput.element.checked = false)
    }
}

function setCountInputValue(countEl: HTMLInputElement, value: number)  {
    const minValue = countEl.hasAttribute('min') ? parseInt(countEl.getAttribute('min') as string, 10) : 0;
    const step = parseInt(countEl.dataset.multiplicity || '1', 10)

    if (value <= minValue) {
        countEl.value = `${minValue}`
        return;
    }

    const newValue = Math.ceil(value / step) * step;

    countEl.value = `${newValue}`
}

function fillProductsMap(countEl: HTMLInputElement, productAttributeID: string) {
    if (productSalesInputsMap.has(productAttributeID)) return;

    const dataAttribute = `data-id-product-attribute="${productAttributeID}"`;

    const salesList = document.querySelector(`.product-card-sales-list[${dataAttribute}]`) as HTMLElement|null;

    if (salesList) {
        const inputs = getSalesInputs(salesList, dataAttribute);

        if (inputs.length > 0) {
            productSalesInputsMap.set(productAttributeID, {
                countElement: countEl as HTMLInputElement,
                salesElements: inputs
            })
        }
    }
}

function setCountBlockEvents(countEl: HTMLInputElement, productAttributeID: string) {
    countEl.addEventListener('change', () => {
        setTimeout(setSaleInputValue, 4, productAttributeID)
    })

    setSaleInputValue(productAttributeID)
}

function findClosestProductSalesInput(countValue: number, salesElements: ProductSalesInput[]): ProductSalesInput|null {
    let closestProductSalesInput: ProductSalesInput|null = null;
    let smallestDifference = Number.MAX_SAFE_INTEGER;

    salesElements.forEach((productSalesInput) => {
        const difference = Math.abs(productSalesInput.count - countValue);

        if (difference < smallestDifference && countValue >= productSalesInput.count) {
            smallestDifference = difference;
            closestProductSalesInput = productSalesInput;
        }
    });

    return closestProductSalesInput;
}

function getSalesInputs(list: HTMLElement, dataAttribute: string) {
    const inputs: ProductSalesInput[] = [];

    list.querySelectorAll(`.product-card-sales-list__field-label[${dataAttribute}]`).forEach((label) => {
        const input = (label as HTMLLabelElement).control as HTMLInputElement|null;

        if (input) {
            inputs.push({
                element: input,
                count: parseInt(input.dataset.quantity as string, 10)
            })
        }
    })

    return inputs;
}
