const className = 'product-card-controls';

document.addEventListener('click', (e) => {
    const target = e.target as HTMLElement;
    const button = target.closest(`.${className}`) as HTMLButtonElement|null;

    if (button && button.dataset.productAccordionId) {
        const accordionID = button.dataset.productAccordionId;
        const activeView = button.dataset.activeView;
        const salePricesBlock = document.querySelector(`.product-card-sales-list[data-product-accordion-id="${accordionID}"]`) as HTMLElement|null;
        const characteristicsBlock = document.querySelector(`.product-card-characteristics[data-product-accordion-id="${accordionID}"]`) as HTMLElement|null;

        if (salePricesBlock && characteristicsBlock) {
            if (activeView === 'characteristics') {
                toggleView(salePricesBlock, characteristicsBlock)
                button.dataset.activeView = 'sales'
            } else {
                toggleView(characteristicsBlock, salePricesBlock)
                button.dataset.activeView = 'characteristics'
            }

            button.classList.toggle(
                'product-card-controls--open',
                button.dataset.initialView !== button.dataset.activeView
            );
        }
    }
})

function toggleView(openView: HTMLElement, closeView: HTMLElement) {
    closeView.style.opacity = '0';
    closeView.style.maxHeight = '0';
    closeView.style.margin = '0';

    const openViewHeight = openView.scrollHeight
    openView.removeAttribute('style');

    openView.style.opacity = '1';
    openView.style.maxHeight = `${openViewHeight}px`;
}
