let previewModalHandler: typeof import('~/client/vanilla-components/product-preview')['getCommonProductHtml'];

const firePreviewModalHandler = (productID: string) => {
    const modal = document.getElementById('product-preview-dropdown-modal') as Element

    previewModalHandler(productID, modal)
}

document.addEventListener('click', (e) => {
    const previewButton = (e.target as Element).closest<HTMLElement>(`.product-card-preview-button`)
    const productID = previewButton && previewButton.dataset.productId

    if (productID) {
        if (!previewModalHandler) {
            import('~/client/vanilla-components/product-preview').then(({getCommonProductHtml}) => {
                previewModalHandler = getCommonProductHtml

                firePreviewModalHandler(productID)
            })

            return
        }

        firePreviewModalHandler(productID)
    }
})


