import {uploadListProductsByColor} from "~/helpers/upload-list-products-by-color";

document.addEventListener('click', (e) => {
    const colorsButton = (e.target as HTMLElement).closest<HTMLElement>('[data-relation="popup-colors"]')

    if (colorsButton && colorsButton.dataset.productId) {
        const productID = Number(colorsButton.dataset.productId)

        if (productID && productID > 0) {
            uploadListProductsByColor(productID)
        }
    }
})
